@import 'variables';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url(./../assets/fonts/Comfortaa-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: normal;
  src: local('Comfortaa'), url(./../assets/fonts/Comfortaa-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: bold;
  src: local('Comfortaa'), url(./../assets/fonts/Comfortaa-Bold.ttf) format('opentype');
}


@font-face {
  font-family: 'Kaff';
  src: local('Kaff'), url(./../assets/fonts/29LT-Kaff-regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Kaff';
  font-weight: normal;
  src: local('Kaff'), url(./../assets/fonts/29LT-Kaff-regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Kaff';
  font-weight: bold;
  src: local('Kaff'), url(./../assets/fonts/29LT-Kaff-Bold.ttf) format('opentype');
}
@font-face {
  font-family: 'TurbinadoBold';
  font-weight: bold;
  src: local('TurbinadoBold'), url(./../assets/fonts/turbinado-bold.ttf) format('opentype');
}
@font-face {
  font-family: 'TurbinadoBold';
  font-weight: normal;
  src: local('TurbinadoBold'), url(./../assets/fonts/turbinado-bold.ttf) format('opentype');
}
@font-face {
  font-family: 'Turbinado';
  font-weight: normal;
  src: local('Turbinado'), url(./../assets/fonts/turbinado-bold.ttf) format('opentype');
}*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.body-container {
  min-height: 120vh;
}

.header-container {
  background-color: $dark-blue;
  padding: 0;
}
.ant-checkbox + span {
  color: white;
}

.header_menu {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app-container {
  max-width: 1200px;
  margin: auto;
}

.footer-container {
  /*height: 250px;
  background-color: #2C3639;*/
}

.ant-form-item {
  &.mb-0 {
    margin-bottom: 0;
  }
}

.error-message {
  display: block;
  color: $error;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

.progress_container {
  .ant-progress {
    position: fixed;
  }
}

.brief-container-wrapper {
  margin-left: -90px;
  border: 1px solid #d9d9d9;
}

.brief-layout-wrapper {
  width: calc(100vw - 220px);
  margin: 0 0 0 -110px;
  border: 1px solid #d9d9d9;

  .header-content {
    h3 {
      margin-right: 100px;
    }
  }

  .qu-brief-main {
    max-height: calc(100vh - 187px);
    min-height: calc(100vh - 187px);
  }

  .qu-channels-grid {
    .ant-col-md-6 {
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
  }
}

.qu-sidebar .sidebar-menu .ant-menu-item-selected {
  color: #6e8590 !important;
  font-weight: 400 !important;
}

.qu-sidebar .sidebar-menu .ant-menu-submenu-open .ant-menu-submenu-title {
  color: #6e8590 !important;
  font-weight: 400 !important;

  &:hover {
    color: $black !important;
  }
}

.qu-sidebar .sidebar-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #6e8590 !important;
  font-weight: 400 !important;

  &:hover {
    color: $black !important;
  }
}

.qu-sidebar .sidebar-menu .active {
  color: $black !important;
  font-weight: 600 !important;
}

.ant-dropdown-menu-item {
  .discount-item {
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
}

.barchart-height-stabilize {
  margin-bottom: -50px;
}

.qu-metrics-card {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-grid {
    padding: 0;
  }
}

.currency_dropdown {
  width: 100px;
}

.cursorPointer {
  cursor: pointer;
}

.ant-notification {
  z-index: 1050 !important;
}

.product-sub-type {
  font-size: 14px;
}

.d-none {
  display: none !important;
}

.greyscale {
  filter: grayscale(100%);
}

/**/
/* marings */
/**/
.mt--25 {
  margin-top: 4px;
}

.mt--50 {
  margin-top: 8px;
}

.mt--75 {
  margin-top: 12px;
}

.mt--100 {
  margin-top: 16px;
}

.mt--125 {
  margin-top: 20px;
}

.mt--150 {
  margin-top: 24px;
}

.mt--175 {
  margin-top: 28px;
}

.mt--200 {
  margin-top: 32px;
}

.mt--300 {
  margin-top: 48px;
}

.mt--400 {
  margin-top: 64px;
}

.ant-card-type-inner .ant-card-body {
  padding: 0px;
}

.ant-card {
  background: none;
}

.vi__character--default {
  border: 1px solid $gray !important;
  color: $gray !important;
  font-size: 24px !important;
}

.ant-steps-item-tail {

}

.StepButton-d10-0-2-32 {
  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: rgb(255, 180, 180);
}

.StepButton-0-2-4 {
  width: 2em;
  border: none;
  cursor: pointer;
  height: 2em;
  margin: 0px;
  display: flex;
  padding: 0px;
  font-size: 1em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: rgb(255, 180, 180);
}

.ant-card-type-inner .ant-card-head {
  background-color: $primary;
  border-radius: 15px;
  color: white;
}

.ant-table-thead > tr > th {
  background-color: $primary;
  color: white;
}

.ant-table.ant-table-small {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background-color: $dark_blue;
  color: white;
}

.ant-table-footer {
  background-color: $light-blue;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: white;
  overflow: hidden;
}

.ant-table-tbody > tr > td {
  border-bottom: 0.5px solid $light-blue;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: $light-blue;
}

.no-padding {
  padding: 0 !important;
}

.no-top-border {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.no-bottom-border {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-btn-link {
  color: #00adee
}

.vi__character--inactive--default {
  background-color: #abe0f9 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  height: 14px;
  background-repeat: no-repeat;
  margin-top: -7px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  height: 14px;
  background-repeat: no-repeat;
  margin-top: -7px;
  background-color: transparent !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  height: 14px;
  background-repeat: no-repeat;
  margin-top: -7px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $dark-blue;
  border-color: $dark-blue;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #FFC42F;
}

.ant-steps-item-title {
  color: $dark-blue !important;
  font-size: 12px !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: rgba(0, 59, 135, 0.2);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 59, 135, 0.2);
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: $dark-blue;
  border-color: $dark-blue;
}

.ant-steps-finish-icon {
  color: $white;
  display: inline-flex;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #00A4E7;
}

.react-tel-input .form-control {
  padding-right: 48px;
  width: 100%;
}

.ant-layout-header {
  height: auto !important;
}

.ant-table-wrapper {
  //background-color: $dark_blue;
}

.ant-table {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.verify-card {
  .ant-card-body{
  }
}
.overlay {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.language-select {
  width: 90px;
  margin-right: 10px;
  background-color: $dark-blue;
}
.hide-border-bottom .ant-form-item-control-input-content {
  border-bottom: none !important;
}
.logo {
  position: absolute;
  top: 65px;
  left: 45px;
  width: 12%;
  cursor: pointer;
}

.header-button {
  color: white;
  font-size: 12px;
}

.tickets-logo {
  position: absolute;
  top: 12%;
  left: 40%;
  width: 22%;
}

.splash_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.splash-logo {
  display: block;
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

.my-tickets {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 130px !important;
  height: 35px !important;
  border-radius: 17px !important;
  font-size: 12px;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border: 1px solid $primary;
  color: $primary;
}

.ant-table-placeholder {
  color: white !important;
}

.ant-input-borderless, .ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled] {
  color: white !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: $light_blue !important;
  color: white;
}

.ant-upload.ant-upload-select-picture-card{
  width: 100%;
  min-height: 200px;
}

/// footer

:root {
  --color-primary: #004A9C;
  --color-primary-light: #00A1ED;
  --color-danger: #C6005B;
  --color-danger-light: #a70450;
  --color-warning-gold: #FFBE00;
  --color-gold: #FDD400;
}


.ant-layout-footer {
  padding: 0 !important;
}


#blue-ring {
  width: 60%;
  position: absolute;
  right: -23rem;
  top: -65rem;
  z-index: -1;
}


#water-effect {
  top: -19rem;
}

.loader {

  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 99990;
}

#after-loader {
  transform: scale(1.5);
}

/* END: animcations */

.letter-space {
  letter-spacing: 10px;
}

.hero-img {
  position: sticky;
  top: 0;
  background: url('https://cyanwp.com/assets/backgrounds/hero-img-main.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}


/* START: buttons */
.btn-danger {
  background-color: var(--color-danger) !important;
  border: 2px solid var(--color-danger) !important;
}

.btn-danger:hover {
  background-color: var(--color-danger-light) !important;
  border: 2px solid var(--color-danger-light) !important;
}


#btn-buy,
.navbar-brand {
  z-index: 100;
}

/* END: buttons */


/* START: backgrounds */
.bg-primary {
  background-color: var(--color-primary) !important;
}

/* START: backgrounds */


/* START: text colors */
.text-primary-light {
  color: var(--color-primary-light) !important;
}

.text-warning-gold {
  color: var(--color-warning-gold) !important;
}

/* END: text colors*/

/* START: cards */
.card-icon {
  width: 22%;
  margin: auto;
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
}

#card1 {
  background: url('https://cyanwp.com/assets/backgrounds/location-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 260px;

  z-index: 2;
}

#card2 {
  background: url('https://cyanwp.com/assets/backgrounds/time-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 260px;

  z-index: 2;
}

#card3 {
  background: url('https://cyanwp.com/assets/backgrounds/map-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 260px;
  margin-top: 90px;
  margin-bottom: 120px;
  z-index: 2;
}

#card4 {
  background: url('https://cyanwp.com/assets/backgrounds/contact-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 260px;
  margin-top: 90px;
  margin-bottom: 120px;
  z-index: 2;
}

.time-clip {
  right: -25%;
  top: -60px;
  transform: scale(1.2);
  z-index: -2;
}

.yellow-ring {
  top: -15px;
  right: -70%;
  transform: scale(0.7);
}

/* START: cards */

/* rings */
#primary-ring {
  position: relative;
  top: -440px;
  left: -150px;
  z-index: -10;
}


/* START: content-section */

#content-section {
  position: relative;
}

#content-section::before {
  content: "";
  position: absolute;
  top: -180px;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("https://cyanwp.com/assets/backgrounds/section-bg.png");
  z-index: -1;
  background-size: cover;

}

/* END: content-section */


/* START:footer */

#footer-clip-path {
  background: url('https://cyanwp.com/assets/backgrounds/footer-clip-path.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#content-section {
  z-index: 1;
}

footer .navbar-nav .nav-link:hover {
  color: var(--color-primary-light) !important;
}

.follow-us-border {
  border-bottom: 2px solid var(--color-primary-light);
}

.footer-row-border {
  border-bottom: 2px solid var(--color-warning-gold);
}

.social-icons {
  display: flex;
  justify-content: center;
}

.social-icons a {
  border: 3px solid var(--color-primary-light);
  padding: 6px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}


.social-icons a:hover {
  background-color: var(--color-warning-gold);
  border: 3px solid var(--color-warning-gold);
}

#footer-clip {
  max-width: 130px;
  width: 100%;
  top: -100px;
  left: -30px;
  z-index: 1;
}

@media(max-width: 800px) {
  .logo {
    top: 55px;
    left: 20px;
    width: 15%;
  }
  .tickets-logo {
    top: 8%;
    left: 40%;
    width: 20%;
  }
  .my-tickets {
    right: 23px;
    width: 100px !important;
    height: 30px !important;
    top: 28px !important;
    font-size: 11px;
  }


  #card1,
  #card2,
  #card3,
  #card4 {
    height: 180px;
    margin: 35px 0;
  }


  .letter-space {
    letter-spacing: 5px;
  }

  #hero-img {
    height: unset;
  }

  #btn-buy {
    width: 100%;
  }

  #water-effect {
    top: 0rem !important;
  }

  .hero-img {
    height: 100vh;
  }

  body {
    overflow-x: hidden !important;
  }

  #footer-clip {
    max-width: 110px;
    width: 100%;
    top: -91px;
    left: -30px;
    z-index: 1;
  }

  #blue-ring {
    width: 50%;
    right: -25;
    top: -50rem;
  }

  #content-section::before {
    background-color: #fff;
    position: unset;
  }

  #content-section {
    background-color: #fff;
    overflow-x: hidden;
  }
}


@media(max-width: 400px) {

  .text-turbinado {
    font-size: 4.5rem !important;
  }

  #after-loader {
    transform: scale(4.5);
  }

  #water-effect {
    top: 0rem !important;
  }
}

@media (max-width: 850px) {
  #blue-ring {
    right: -12rem;
    top: -65rem;
  }
}

@media (max-width: 970px) {
  .time-clip {
    right: -25%;
    top: -23px !important;
    transform: scale(1.2);
    z-index: -2;
  }

  #content-section {
    overflow-x: hidden !important;
  }

  #water-effect {
    top: -3rem;
  }
}

@media (max-width: 1285px) {
  #water-effect {
    top: -9rem;
  }

  .time-clip {
    right: -25%;
    top: -49px !important;
    transform: scale(1.2);
    z-index: -2;
  }
}
