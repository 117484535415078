@import './../variables';
.accept-button {
  background-color: $primary;
  margin-top: 20px;
  height: 50px;
  border: none;
  border-radius: 10px;
  color: white;
}
.accept-button:hover {
  border: 1px solid #00BFFF;
  background-color: #00BFFF;
  color: white;
}
.info-box {
  background-color: #F7D060;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.success-block {
  background-color: rgba(0,0,0,0.8);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  inset: 0
}
.thank-you {
  color: #fff;
  font-size: 100pt;
  text-align: center;
  width:100%
}
.thank-you-message {
  font-size: 25pt;
}
@media only screen and (max-width: 768px) {
  .thank-you {
    font-size: 40pt;
    font-weight: bold;
  }
  .thank-you-message {
    font-size: 18pt;
    padding-right: 10px;
    padding-left: 10px;
  }
}
